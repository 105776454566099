import React from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import { routes } from 'router/routes';
import { userApi } from 'services/UserService';
import { UserRoleType } from 'types';

import Refetch from 'components/Refetch';
import Spinner from 'components/Spinner';

import NavbarDropDown from './NavbarDropDown';
import NavbarMenu from './NavbarMenu';
import NavbarNotification from './NavbarNotification';

export default function Navbar() {
  const { data: user, isLoading, refetch } = userApi.useGetCurrentUserQuery();

  if (isLoading) {
    return <Spinner />;
  }

  if (!user) {
    return <Refetch refetch={refetch} />;
  }

  return (
    <header className="sticky top-0 z-10 flex h-14 items-center justify-between bg-white px-0 tablet:h-10">
      <div className="flex h-14 items-center tablet:h-10">
        <Link
          to={routes.dashboard}
          title="Go to Main"
          className="flex items-center gap-2 pl-4 pr-2 tablet:px-1 tablet:py-[5px]"
        >
          <span className="typography-h2 tablet:typography-h3 font-bold text-primary">TMCS</span>
          <span className="typography-caption-small w-36 text-capture tablet:hidden">
            Technical&nbsp;Maintenance Control&nbsp;System
          </span>
        </Link>

        <NavbarMenu user={user} />
      </div>

      <Space className="gap-[10px] pr-4">
        {user.role !== UserRoleType.Translator && <NavbarNotification />}
        <NavbarDropDown user={user} />
      </Space>
    </header>
  );
}
