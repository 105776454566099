import React, { CSSProperties } from 'react';

export const yearFormat = 'YYYY';
export const monthFormat = 'MM.YYYY';
export const dateFormat = 'DD.MM.YYYY';
export const requestDateFormat = 'YYYY-MM-DD';
export const weekFormat = 'WW.YYYY';
export const dateTimeFormat = 'DD.MM.YYYY HH:mm';
export const dateTimeReportFormat = 'DD-MM-YYYY HH-mm';

export const STATUSES = ['Pending', 'InWork', 'Closed'];

export const bodyStyle: React.CSSProperties = { padding: '16px 24px' };

export const labelStyle: CSSProperties = {
  fontWeight: 'bold',
};

export const DASHBOARD_BACKGROUND = 'rgba(0,0,0,0.01)';

export const DASHBOARD_BORDER = 'border border-border';

export const email = 'tmcs@dooidc.rs';
