import { memo } from 'react';
import { Button, Typography } from 'antd';

interface RefetchProps {
  refetch: () => void;
}

const Refetch = memo(function Refetch({ refetch }: RefetchProps) {
  return (
    <>
      <Typography.Title>Something went wrong!</Typography.Title>
      <Button type="primary" onClick={refetch}>
        Refetch
      </Button>
    </>
  );
});

export default Refetch;
