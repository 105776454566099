import { createApi } from '@reduxjs/toolkit/query/react';
import { SubjectResponse } from 'types';

import { baseQuery } from './baseQuery';

export const subjectApi = createApi({
  reducerPath: 'subjectApi',
  baseQuery: baseQuery,
  tagTypes: ['Subjects'],
  endpoints: builder => ({
    getAllSubjects: builder.query<SubjectResponse[], void>({
      query: () => ({
        url: 'Subjects',
      }),
      providesTags: () => ['Subjects'],
    }),
  }),
});
