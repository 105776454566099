import dayjs, { OpUnitType } from 'dayjs';
import { getPeriodPicker } from 'pages/PlansPage/PlansSearch/utils';
import { PlanPeriod, UnscheduledTaskGlobalStatus, UnscheduledTaskStatus } from 'types';
import { Option } from 'types/global';
import { requestDateFormat } from 'utils/constants';
import removeEmptyKeys, { mapForParams } from 'utils/removeEmptyKeys';

const pageSize = 10000;

export const initialParams = {
  pageSize,
};

export interface ReportTasksFormValues {
  subjectsIds: number[];
  date: Date | undefined;
  companiesIds: number[];
  globalStatuses: UnscheduledTaskGlobalStatus[];
  statuses: UnscheduledTaskStatus[];
}

export const initialValues: ReportTasksFormValues = {
  subjectsIds: [],
  date: undefined,
  companiesIds: [],
  globalStatuses: [],
  statuses: [],
};

export const convertValuesToParams = (values: ReportTasksFormValues, period: PlanPeriod) => {
  const {
    date,
    companiesIds: _companiesIds,
    statuses: _statuses,
    subjectsIds: _subjectsIds,
    globalStatuses: _globalStatuses,
  } = values;

  const _Date = date ?? new Date();

  const _startEndOf = getPeriodPicker(period) as OpUnitType;

  const from = _startEndOf && dayjs(_Date).startOf(_startEndOf).format(requestDateFormat);
  const to = _startEndOf && dayjs(_Date).endOf(_startEndOf).format(requestDateFormat);

  const [subjectsIdsFiltered, subjectWithChildIdsFiltered] = _subjectsIds.reduce(
    (acc, curr) => {
      // Сортируем выбранные на задачи + задач с подзадачами в зависимости от знака ID
      // после сортировки сохраняем значение ID по модулю
      acc[curr < 0 ? 1 : 0].push(Math.abs(curr));
      return acc;
    },
    [[], []] as [number[], number[]],
  );

  const companiesIds = mapForParams('companiesIds', _companiesIds);
  const subjectsIds = mapForParams('subjectsIds', subjectsIdsFiltered);
  const subjectWithChildIds = mapForParams('subjectWithChildIds', subjectWithChildIdsFiltered);
  const statuses = mapForParams('statuses', _statuses);
  const globalStatuses = mapForParams('globalStatuses', _globalStatuses);

  const res = removeEmptyKeys({
    from,
    to,
    ...companiesIds,
    ...statuses,
    ...globalStatuses,
    ...subjectsIds,
    ...subjectWithChildIds,
  });

  return { ...initialParams, ...res };
};

export const filterOption = (input: string, option: Option | undefined) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase().trim());

export const SUCCESS_MESSAGE = 'Task Updated';
