import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectProps } from 'antd';
import { PlansSearch as DashboardSearch } from 'pages/DashboardPage/components/DashboardSearchForm';
import { DashboardParamsProps } from 'pages/DashboardPage/utils';
import { SearchValues } from 'pages/DwgAndVendorPage/SearchServiceUnit/utils';
import { EmergencyTaskSearchFormValues } from 'pages/EmergencyTaskPage/utils';
import { initialParams as emergencyTasksInitialParams } from 'pages/EmergencyTaskPage/utils';
import { LogParamsProps } from 'pages/LogsPage/utils';
import { PlansSearch } from 'pages/PlansPage/PlansSearch/PlansSearchForm';
import { initialParams as reportTasksInitialParams, ReportTasksFormValues } from 'pages/ReportTasksPage/utils';
import { TaskSearchFormValues } from 'pages/ScheduledWorkOrdersPage/components/TasksSearchForm/utils';
import { initialParams as plannedTasksInitialParams } from 'pages/ScheduledWorkOrdersPage/utils';

type VendorDocsFilters = {
  search?: SearchValues;
  tagOptions?: SelectProps['options'];
  groupOptions?: SelectProps['options'];
};

interface PlansSearchFilters extends Omit<PlansSearch, 'Date'> {
  Date: string | undefined;
}

interface UniverseTasksFilters {
  [k: string]: unknown;
}

type PlannedTasksFilters = typeof plannedTasksInitialParams & UniverseTasksFilters;

type EmergencyTasksFilters = typeof emergencyTasksInitialParams & UniverseTasksFilters;

type ReportTasksFilters = typeof reportTasksInitialParams & UniverseTasksFilters;

type TranslationTasksFilters = UniverseTasksFilters;

interface LogFileSearchFormValues {
  [k: string]: unknown;
}

export interface Filters {
  plans?: PlansSearchFilters;
  plansFormData?: PlansSearch;
  vendorDocs?: VendorDocsFilters;
  vendorDocsFormData?: UniverseTasksFilters;
  plannedTasks?: PlannedTasksFilters;
  plannedTasksFormData?: TaskSearchFormValues;
  emergencyTasks?: EmergencyTasksFilters;
  emergencyTasksFormData?: EmergencyTaskSearchFormValues;
  reportTasks?: ReportTasksFilters;
  reportTasksFormData?: ReportTasksFormValues;
  dashboard?: DashboardParamsProps;
  dashboardFormData?: DashboardSearch;
  logFile?: LogParamsProps;
  logFileFormData?: LogFileSearchFormValues;
  translationTasks?: TranslationTasksFilters;
  translationTasksFormData?: object;
}

const initialState: Filters = {
  plannedTasks: plannedTasksInitialParams,
  emergencyTasks: emergencyTasksInitialParams,
  reportTasks: reportTasksInitialParams,
};

export const filterSlice = createSlice({
  name: 'filterSlice',
  initialState,
  reducers: {
    setPlansSearchFilters: (state, action: PayloadAction<PlansSearchFilters | undefined>) => {
      state.plans = action.payload;
    },
    setPlansSearchFormData: (state, action: PayloadAction<PlansSearch | undefined>) => {
      state.plansFormData = action.payload;
    },
    setVendorDocsFilters: (state, action: PayloadAction<VendorDocsFilters | undefined>) => {
      state.vendorDocs = action.payload;
    },
    setVendorDocsFormData: (state, action: PayloadAction<UniverseTasksFilters | undefined>) => {
      state.vendorDocsFormData = action.payload;
    },
    setPlannedTasksFilters: (state, action: PayloadAction<PlannedTasksFilters | undefined>) => {
      state.plannedTasks = action.payload;
    },
    setPlannedTasksFormData: (state, action: PayloadAction<TaskSearchFormValues | undefined>) => {
      state.plannedTasksFormData = action.payload;
    },
    setEmergencyTasksFilters: (state, action: PayloadAction<EmergencyTasksFilters | undefined>) => {
      state.emergencyTasks = action.payload;
    },
    setEmergencyTasksFormData: (state, action: PayloadAction<EmergencyTaskSearchFormValues | undefined>) => {
      state.emergencyTasksFormData = action.payload;
    },
    setReportTasksFilters: (state, action: PayloadAction<ReportTasksFilters | undefined>) => {
      state.reportTasks = action.payload;
    },
    setReportTasksFormData: (state, action: PayloadAction<ReportTasksFormValues | undefined>) => {
      state.reportTasksFormData = action.payload;
    },
    setDashboardFilters: (state, action: PayloadAction<DashboardParamsProps | undefined>) => {
      state.dashboard = action.payload;
    },
    setDashboardFormData: (state, action: PayloadAction<DashboardSearch | undefined>) => {
      state.dashboardFormData = action.payload;
    },
    setLogFileFilters: (state, action: PayloadAction<LogParamsProps | undefined>) => {
      state.logFile = action.payload;
    },
    setLogFileFormData: (state, action: PayloadAction<LogFileSearchFormValues | undefined>) => {
      state.logFileFormData = action.payload;
    },
    setTranslationTasksFilters: (state, action: PayloadAction<TranslationTasksFilters | undefined>) => {
      state.translationTasks = action.payload;
    },
    setTranslationTasksFormData: (state, action: PayloadAction<object | undefined>) => {
      state.translationTasksFormData = action.payload;
    },
  },
});

export const {
  setPlansSearchFilters,
  setVendorDocsFilters,
  setPlannedTasksFilters,
  setEmergencyTasksFilters,
  setReportTasksFilters,
  setDashboardFilters,
  setPlansSearchFormData,
  setVendorDocsFormData,
  setPlannedTasksFormData,
  setEmergencyTasksFormData,
  setReportTasksFormData,
  setDashboardFormData,
  setLogFileFilters,
  setLogFileFormData,
  setTranslationTasksFilters,
  setTranslationTasksFormData,
} = filterSlice.actions;

export default filterSlice.reducer;
