export default async function getBlobFile(response: any, open = false) {
  const filename = response.headers
    .get('content-disposition')
    .split(';')
    .find((n: any) => n.includes("filename*=UTF-8''"))
    .replace("filename*=UTF-8''", '')
    .replaceAll('"', '')
    .trim();

  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', decodeURI(filename)); //or any other extension
  document.body.appendChild(link);
  open ? window.open(href, '_blank', 'noreferrer') : link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}
