import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Pagination, Skeleton } from 'antd';
import SearchServiceUnitCard from 'pages/DwgAndVendorPage/SearchServiceUnit/SearchServiceUnitCard';
import { nodeAPI } from 'services/NodeService';
import { NodeResponse, SlimServiceUnitResponse } from 'types';

interface TreeNodeServiceUnitsProps {
  node: NodeResponse;
}

const pageSize = 10;

const TreeNodeServiceUnits = ({ node }: TreeNodeServiceUnitsProps) => {
  const [searchParams] = useSearchParams();
  const pagination = searchParams.get('p');
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(() => (pagination ? Number(pagination) : 1));
  const [serviceUnits, setServiceUnits] = useState<SlimServiceUnitResponse[]>([]);

  const [fetchServiceUnits, { isLoading }] = nodeAPI.useLazyGetNodeServiceUnitsQuery();

  const handleFetch = async () => {
    try {
      const { totalPages, data: serviceUnits } = await fetchServiceUnits({
        nodeId: node.id ?? Number.NaN,
        params: { pageNumber, pageSize },
      }).unwrap();
      setTotalPages(totalPages);
      setServiceUnits(serviceUnits);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (node && node.hasServiceUnits) {
      setTotalPages(1);
      setPageNumber(pagination ? Number(pagination) : 1);
      handleFetch();
    }
  }, [node.id]);

  useEffect(() => {
    handleFetch();
  }, [pageNumber]);

  return (
    <>
      {isLoading && Array.from({ length: 10 }).map((_, index) => <Skeleton key={index} paragraph={{ rows: 1 }} />)}
      {!isLoading && (
        <div className="grid grid-cols-1 gap-4 p-4">
          {serviceUnits.map(serviceUnit => (
            <SearchServiceUnitCard
              key={serviceUnit.id}
              serviceUnit={serviceUnit}
              showPath={false}
              className="overflow-hidden border border-solid border-border p-0 [&&&]:rounded-lg [&>div]:!p-0"
            />
          ))}
        </div>
      )}
      <Pagination
        current={pageNumber}
        total={totalPages * pageSize}
        pageSize={pageSize}
        hideOnSinglePage
        onChange={page => {
          setPageNumber(page);
          navigate(`?p=${page}`);
        }}
        showSizeChanger={false}
      />
    </>
  );
};

export default TreeNodeServiceUnits;
