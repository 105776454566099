import { createApi } from '@reduxjs/toolkit/query/react';
import { PositionResponse } from 'types';

import { baseQuery } from './baseQuery';

export const positionApi = createApi({
  reducerPath: 'positionApi',
  baseQuery: baseQuery,
  tagTypes: ['Positions'],
  endpoints: builder => ({
    getPositions: builder.query<PositionResponse[], void>({
      query: () => ({
        url: 'Positions',
      }),
      providesTags: () => ['Positions'],
    }),
  }),
});
