import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, Tag, Typography } from 'antd';
import clsx from 'clsx';
import { routes } from 'router/routes';
import { ReactComponent as DirectorySvg } from 'svg/directory.svg';
import { SlimServiceUnitResponse } from 'types';

const { Text } = Typography;

interface SearchServiceUnitCardProps {
  serviceUnit: SlimServiceUnitResponse;
  showPath?: boolean;
  className?: string;
}

export default function SearchServiceUnitCard({
  serviceUnit,
  showPath = true,
  className = '',
}: SearchServiceUnitCardProps) {
  const { t } = useTranslation('vendor-docs');
  const { id, name, node, tags, fileCount } = serviceUnit;
  return (
    <Link to={routes.serviceUnit + '/' + id}>
      <Card hoverable bordered={false} className={clsx('relative', className)} size="small">
        <div className="flex min-h-[100px] items-start justify-between bg-quaternary py-4 pl-3 pr-4">
          <div className="flex h-full flex-col justify-center">
            <div className="mb-4">
              <div className="flex items-center">
                <DirectorySvg />
                <h5 className="typography-caption-text inline pl-2 font-bold text-primary">{name}</h5>
              </div>
              <Text type="secondary">{showPath && node?.nodePath}</Text>
            </div>
            <ul className="relative m-0 flex overflow-hidden">
              {tags?.map(tag => (
                <li key={tag}>
                  <Tag
                    title={tag}
                    className="bg-containerDisabled px-1 text-[10px] font-bold leading-[22px] text-third"
                  >
                    {tag}
                  </Tag>
                </li>
              ))}
            </ul>
          </div>
          <Tag className="mr-0 border-colorSplit bg-containerDisabled px-4 text-[10px] font-bold leading-[22px] text-specialGreen">
            {fileCount} {t('files')}
          </Tag>
        </div>
      </Card>
    </Link>
  );
}
