import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import AntdProvider from 'providers/AntdProvider';
import AppProvider from 'providers/AppContextProvider';
import { ErrorBoundary } from 'providers/ErrorBoundary';
import { store } from 'redux/store';

import './i18n';

import App from './App';

// import '@ant - design/flowchart/dist/index.css'
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ErrorBoundary>
      <AntdProvider>
        <Provider store={store}>
          <AppProvider>
            <App />
          </AppProvider>
        </Provider>
      </AntdProvider>
    </ErrorBoundary>
  </BrowserRouter>,
  // </React.StrictMode>
);
