// import type { MiddlewareAPI } from '@reduxjs/toolkit';
import { combineReducers, configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import { message } from 'antd';
import { companyAPI } from 'services/CompanyService';
import { emergencyTaskApi } from 'services/EmergencyTaskService';
import { enumApi } from 'services/EnumService';
import { fileApi } from 'services/FileService';
import { nodeAPI } from 'services/NodeService';
import { positionApi } from 'services/PositionService';
import { scheduledWorkOrderApi } from 'services/ScheduledWorkOrdersService';
import { serviceUnitApi } from 'services/ServiceUnitService';
import { statisticsAPI } from 'services/StatisticsService';
import { subjectApi } from 'services/SubjectService';
import { tagApi } from 'services/TagService';
import { translationApi } from 'services/TranslationService';
import { unscheduledTaskApi } from 'services/UnscheduledTasksService';
import { userApi } from 'services/UserService';

import filesReducer from './filesSlice';
import filterReducer from './filterSlice';
import mainReducer from './mainSlice';

const rootReducer = combineReducers({
  mainSlice: mainReducer,
  filesSlice: filesReducer,
  filterSlice: filterReducer,
  [userApi.reducerPath]: userApi.reducer,
  [companyAPI.reducerPath]: companyAPI.reducer,
  [nodeAPI.reducerPath]: nodeAPI.reducer,
  [subjectApi.reducerPath]: subjectApi.reducer,
  [emergencyTaskApi.reducerPath]: emergencyTaskApi.reducer,
  [enumApi.reducerPath]: enumApi.reducer,
  [fileApi.reducerPath]: fileApi.reducer,
  [serviceUnitApi.reducerPath]: serviceUnitApi.reducer,
  [tagApi.reducerPath]: tagApi.reducer,
  [positionApi.reducerPath]: positionApi.reducer,
  [scheduledWorkOrderApi.reducerPath]: scheduledWorkOrderApi.reducer,
  [statisticsAPI.reducerPath]: statisticsAPI.reducer,
  [unscheduledTaskApi.reducerPath]: unscheduledTaskApi.reducer,
  [translationApi.reducerPath]: translationApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(userApi.middleware)
      .concat(companyAPI.middleware)
      .concat(nodeAPI.middleware)
      .concat(subjectApi.middleware)
      .concat(emergencyTaskApi.middleware)
      .concat(enumApi.middleware)
      .concat(fileApi.middleware)
      .concat(serviceUnitApi.middleware)
      .concat(tagApi.middleware)
      .concat(positionApi.middleware)
      .concat(scheduledWorkOrderApi.middleware)
      .concat(statisticsAPI.middleware)
      .concat(unscheduledTaskApi.middleware)
      .concat(translationApi.middleware)
      .concat(() =>
        // api: MiddlewareAPI
        next => async action => {
          if (isRejectedWithValue(action)) {
            const { status, originalStatus } = action.payload;
            if (originalStatus && originalStatus === 500) {
              console.error('500', action);
              return message.error('Server error code 500: please, report to developers!');
            }
            if (status && status === 403) {
              console.error('403', action);
              return message.error('You do not have permit for the action!');
            }
            if (status && status === 401) {
              console.error('401', action);
              return message.error('You do not have permit for the action!');
            }
            console.error('We got a rejected with value action!', action, action.payload.data);
            await message.error(
              action.payload.data.detail ||
                action.payload.data.errors.Comment[0] ||
                action.payload.data.errors.Comment[0],
            );
          }

          return next(action);
        },
      ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
