import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { UserResponse } from 'types';
import { Language } from 'types/global';

export interface MainState {
  accessToken: string;
  refreshToken: string;
  user: UserResponse | undefined;
  isLoading: boolean;
  error: string;
  unread: boolean;
  fresh: boolean;
  language: Language;
}

const initialState: MainState = {
  accessToken: '',
  refreshToken: '',
  user: undefined,
  isLoading: false,
  error: '',
  unread: false,
  fresh: false,
  language: Language.en,
};

export const mainSlice = createSlice({
  name: 'mainSlice',
  initialState,
  reducers: {
    setTokens: (
      state,
      action: PayloadAction<{
        accessToken: string;
        refreshToken: string;
      }>,
    ) => {
      const { accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
    },
    setUser: (state, action: PayloadAction<UserResponse>) => {
      state.user = action.payload;
    },
    logout: state => {
      state.accessToken = '';
      state.refreshToken = '';
      state.user = undefined;
      state.error = '';
      state.isLoading = false;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setUnread: (state, action: PayloadAction<boolean>) => {
      state.unread = action.payload;
    },
    setFresh: (state, action: PayloadAction<boolean>) => {
      state.fresh = action.payload;
    },
    setLanguage: (state, action: PayloadAction<Language>) => {
      state.language = action.payload;
    },
  },
});

export const {
  setTokens,
  setUser,
  logout,
  setError,
  // setNodeId,
  setIsLoading,
  setUnread,
  setFresh,
  setLanguage,
} = mainSlice.actions;

export default mainSlice.reducer;
