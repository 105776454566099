import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  CreateNodeRequest,
  CreateNodeResponse,
  NodeResponse,
  SlimServiceUnitResponsePaginationResponse,
  TreeNodeResponse,
} from 'types';
import { GetParams } from 'types/global';

import { baseQuery } from './baseQuery';

type GetNodesParams = GetParams & { nodePath?: string };

export const nodeAPI = createApi({
  reducerPath: 'nodeAPI',
  baseQuery: baseQuery,
  tagTypes: ['Tree', 'Node', 'SupportedExtension', 'ServiceUnits'],
  endpoints: build => ({
    getRootNodes: build.query<TreeNodeResponse[], void>({
      query: () => ({
        url: '/Nodes/rootNodes',
      }),
      providesTags: () => ['Tree'],
    }),
    getNodes: build.query<NodeResponse, GetNodesParams>({
      query: params => ({
        url: '/Nodes',
        params,
      }),
      providesTags: () => ['Tree'],
    }),
    getNodeById: build.query<NodeResponse, number>({
      query: id => ({
        url: `/Nodes/${id}`,
      }),
      providesTags: () => ['Node', 'ServiceUnits'],
    }),
    getNodeServiceUnits: build.query<SlimServiceUnitResponsePaginationResponse, { nodeId: number; params: GetParams }>({
      query: ({ nodeId, params }) => ({
        url: `/Nodes/${nodeId}/serviceUnits`,
        params,
      }),
      providesTags: () => [],
    }),
    createNode: build.mutation<CreateNodeResponse, CreateNodeRequest>({
      query: node => ({
        url: '/Nodes',
        method: 'POST',
        body: node,
      }),
      invalidatesTags: ['Tree', 'Node'],
    }),
  }),
});
