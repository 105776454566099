import { Layout } from 'antd';
import clsx from 'clsx';
import useAppContext from 'hooks/useAppContext';
import { email } from 'utils/constants';
import Languages from 'widgets/SelectLanguage/SelectLanguage';

import LoginForm from 'components/Forms/LoginForm';

import ThemeSwitcher from './components/ThemeSwitcher/ThemeSwitcher';

export default function LoginPage() {
  const { theme } = useAppContext();
  return (
    <Layout
      className={clsx('relative', 'flex', 'flex-row', 'items-stretch', 'justify-stretch', {
        'bg-backSpecial': theme === 'light',
        'bg-darkPrimary': theme === 'dark',
      })}
    >
      <ThemeSwitcher className="absolute right-4 top-4 z-10" />
      <Languages className="absolute right-4 top-24 z-10" />
      <div className="relative flex items-center justify-center overflow-hidden sm:w-[38%] sm:max-w-[30rem] 2xl:w-[47%] 2xl:max-w-[56rem]">
        {theme === 'light' && (
          <img
            alt="factory"
            width="906"
            height="1079"
            src="/images/factory.png"
            className="absolute min-h-full min-w-full object-cover"
          />
        )}
        {theme === 'dark' && (
          <img
            alt="factory_dark"
            width="899"
            height="1080"
            src="/images/factory_dark.png"
            className="absolute min-h-full min-w-full object-cover"
          />
        )}
      </div>
      <div className="relative flex grow flex-col items-center justify-center sm:min-w-[46.5rem] sm:pb-20 2xl:min-w-[63.5rem] 2xl:pb-40 ">
        <h1
          className={clsx('font-bold', 'typography-h1-special', {
            'text-primary': theme === 'light',
            'text-customBlue': theme === 'dark',
          })}
        >
          TMCS
        </h1>
        <h2
          className={clsx('mb-4', 'typography-h2-special', {
            'text-third': theme === 'light',
            'text-lightBlue': theme === 'dark',
          })}
        >
          Technical Maintenance Control System
        </h2>
        <LoginForm />
        <div
          className={clsx(
            'absolute',
            'text-center',
            'font-bold',
            'sm:bottom-9',
            'sm:text-2xl',
            'sm:leading-7',
            '2xl:bottom-16',
            '2xl:text-3xl',
            '2xl:leading-8',
            {
              'text-third': theme === 'light',
              'text-lightBlue': theme === 'dark',
            },
          )}
        >
          <span>Forget your password</span>
          <span> / </span>
          <span>New registration</span>
          <span> / </span>
          <span>Technical support</span>
          <br />
          <span className="mr-4">•</span>
          <a className="uppercase text-inherit hover:text-primary" href={`mailto:${email}`}>
            {email}
          </a>
        </div>
      </div>
    </Layout>
  );
}
