import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { Mutex } from 'async-mutex';
import { logout, setTokens } from 'redux/mainSlice';
import type { RootState } from 'redux/store';
import type { LoginResponse } from 'types';

// create a new mutex
const mutex = new Mutex();
const baseQueryDefault = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND + '/api/v1/',
  prepareHeaders: (headers, { getState }) => {
    const { accessToken, language } = (getState() as RootState).mainSlice;
    headers.set('accept-language', language);

    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
});

// const baseQuery = fetchBaseQuery({ baseUrl: '/' })
export const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQueryDefault(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQueryDefault(
          {
            url: 'Auth/refresh',
            method: 'POST',
            body: {
              accessToken: localStorage.getItem('accessToken'),
              refreshToken: localStorage.getItem('refreshToken'),
            },
          },
          api,
          extraOptions,
        );
        if (refreshResult.data) {
          const { accessToken, refreshToken } = refreshResult.data as Required<LoginResponse>;
          accessToken && refreshToken && api.dispatch(setTokens({ accessToken, refreshToken }));
          // retry the initial query
          result = await baseQueryDefault(args, api, extraOptions);
        } else {
          api.dispatch(logout());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQueryDefault(args, api, extraOptions);
    }
  }
  return result;
};
