import React from 'react';
import { Button, Form, Input } from 'antd';
import clsx from 'clsx';
import useAppContext from 'hooks/useAppContext';
import { useAppDispatch } from 'redux/hooks';
import { setTokens, setUser } from 'redux/mainSlice';
import { userApi } from 'services/UserService';
import type { LoginRequest } from 'types';
import { loginRules } from 'utils/rules';

export default function LoginForm() {
  const { theme } = useAppContext();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [userLogin, { isLoading }] = userApi.useLoginUserMutation();
  const [getCurrent] = userApi.useLazyGetCurrentUserQuery();

  const onFinish = async (credentials: LoginRequest) => {
    try {
      const { accessToken, refreshToken } = await userLogin(credentials).unwrap();
      accessToken && refreshToken && dispatch(setTokens({ accessToken, refreshToken }));
      const user = await getCurrent().unwrap();
      dispatch(setUser(user));
    } catch (e) {
      console.error('e', e);
    }
  };

  return (
    <Form
      name="login_form"
      onFinish={onFinish}
      autoComplete="off"
      className="w-[28rem] text-right [&>.ant-form-item]:!mb-10"
      layout="vertical"
      form={form}
    >
      <Form.Item name="login" rules={loginRules.login}>
        <div className="flex items-center justify-between">
          <label
            className={clsx('!text-2xl', 'font-bold', '!leading-7', {
              'text-primary': theme === 'light',
              'text-lightBlue': theme === 'dark',
            })}
          >
            Login:
          </label>
          <Input size="large" className="h-8 w-72 border-colorBorder py-0" />
        </div>
      </Form.Item>

      <Form.Item name="password" rules={loginRules.password}>
        <div className="flex items-center justify-between">
          <label
            className={clsx('!text-2xl', 'font-bold', '!leading-7', {
              'text-primary': theme === 'light',
              'text-lightBlue': theme === 'dark',
            })}
          >
            Password:
          </label>
          <Input.Password size="large" className="h-8 w-72 border-colorBorder py-0" />
        </div>
      </Form.Item>

      <Form.Item className="flex justify-end">
        <Button
          type="primary"
          className={clsx('h-11', 'w-28', 'text-xl', 'leading-6', {
            'text-primary': theme === 'dark',
            'bg-lightBlue': theme === 'dark',
          })}
          htmlType="submit"
          size="large"
          loading={isLoading}
        >
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
}
