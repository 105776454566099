import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import Draggable, { DraggableEventHandler } from 'react-draggable';
import { CloseOutlined, LineOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import clsx from 'clsx';

interface PortalModalProps {
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

export default function PortalModal({ open, children, onClose }: PortalModalProps) {
  const [curtail, setCurtail] = useState(false);
  const [x, setX] = useState(window.outerWidth / 2 - 200);
  const [y, setY] = useState(-window.outerHeight / 2 - 200);

  const handleStop: DraggableEventHandler = (_e, dragElement) => {
    setX(dragElement.x);
    setY(dragElement.y);
  };

  useEffect(() => {
    if (!open) {
      setCurtail(false);
    }
  }, [open]);

  const onHide = () => {
    if (curtail) {
      setX(window.outerWidth - 450);
      setY(-175);
    } else {
      setX(window.outerWidth / 2 - 200);
      setY(-window.outerHeight / 2 - 200);
    }

    setCurtail(prevState => !prevState);
  };

  if (!open) {
    return null;
  }

  return ReactDom.createPortal(
    <Draggable onStop={handleStop} position={{ x, y }}>
      <div
        className={clsx(
          'absolute',
          'z-50',
          'h-fit',
          'w-fit',
          'rounded',
          'border-[1px]',
          'border-solid',
          'border-black/10',
          'bg-white',
          'px-3',
          'pb-3',
          'pt-1',
          'shadow-2xl',
          'ease-linear',
        )}
      >
        <div className="flex justify-end">
          <Button size="small" type="text" icon={<LineOutlined />} onClick={onHide} />
          <Button size="small" type="text" icon={<CloseOutlined />} onClick={onClose} />
        </div>
        <div>{children}</div>
      </div>
    </Draggable>,
    document.getElementById('portal') as HTMLElement,
  );
}
