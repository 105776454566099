import { DataNode } from 'antd/es/tree';
import { TreeNodeResponse } from 'types';

const excluded = ['DOC USED MATERIALS'];

export default function mapNodes(nodes: TreeNodeResponse[]): DataNode[] {
  return nodes
    .map(node => {
      if (node.children?.length === 0) {
        return {
          title: node.name ?? '',
          key: node.id ?? Number.NaN,
          isLeaf: false,
        };
      }
      return {
        title: node.name ?? '',
        key: node.id ?? Number.NaN,
        isLeaf: excluded.includes(node.name ?? ''),
        children: mapNodes(node.children ?? []),
        disabled: excluded.includes(node.name ?? ''),
      };
    })
    .sort((a, b) => a.title.localeCompare(b.title));
}
