import { ComponentPropsWithoutRef } from 'react';
import { Button } from 'antd';
import clsx from 'clsx';

type PageErrorProps = ComponentPropsWithoutRef<'div'>;

const reloadPage = () => {
  location.reload();
};

export const PageError = ({ className }: PageErrorProps) => {
  return (
    <div className={clsx('flex h-screen w-full flex-col items-center justify-center', className)}>
      <h1>Something went wrong</h1>
      <Button type="primary" onClick={reloadPage} className="mt-4">
        Reload Page
      </Button>
    </div>
  );
};
