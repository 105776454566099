import React from 'react';
import { ConfigProvider, message } from 'antd';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekday from 'dayjs/plugin/weekday';

import { theme } from './theme';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

const AntdProvider = ({ children }: { children: React.ReactNode }) => {
  message.config({ top: 64 });

  return <ConfigProvider theme={theme}>{children}</ConfigProvider>;
};

export default AntdProvider;
