import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoginPage from 'pages/LoginPage/LoginPage';
import MainLayout from 'router/MainLayout';
import { generalContractorOverwriteRoutes, privateRoutes, translatorRoutes } from 'router/routerConfig';
import { routes } from 'router/routes';
import { userApi } from 'services/UserService';
import { CompanyRoleType, UserRoleType } from 'types';
import { Language } from 'types/global';

import { useAppDispatch, useAppSelector } from './redux/hooks';
import { setLanguage, setTokens, setUser } from './redux/mainSlice';

import 'antd/dist/reset.css';

export default function App() {
  const reduxLanguage = useAppSelector(state => state.mainSlice.language);
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.mainSlice.user);
  const [getCurrent] = userApi.useLazyGetCurrentUserQuery();
  const { i18n } = useTranslation();

  useEffect(() => {
    const _language = localStorage.getItem('language');
    const language = _language && _language in Language ? (_language as Language) : reduxLanguage;
    dispatch(setLanguage(language));
    i18n.changeLanguage(language).then(() => console.info(`language is changed to: ${language}`));
  }, [dispatch, i18n, reduxLanguage]);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    if (accessToken && refreshToken) {
      dispatch(setTokens({ accessToken, refreshToken }));
      getCurrent()
        .unwrap()
        .then(user => {
          dispatch(setUser(user));
        });
    }
  }, [dispatch, getCurrent]);

  if (!user) {
    return <LoginPage />;
  }

  if (user.role === UserRoleType.Translator) {
    return (
      <Routes>
        <Route path={routes.main} element={<MainLayout />}>
          {translatorRoutes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
          <Route path="*" element={<Navigate to={routes.translationTask} replace />} />
        </Route>
      </Routes>
    );
  }

  const userWithoutDashboard =
    user.role === UserRoleType.GeneralContractor && user.company.role === CompanyRoleType.GeneralContractor;
  const routesOmitDashboard = privateRoutes
    .filter(({ path }) => !([routes.dashboard, routes.main] as string[]).includes(path ?? ''))
    .concat(generalContractorOverwriteRoutes);
  const allowedRoutes = userWithoutDashboard ? routesOmitDashboard : privateRoutes;
  const defaultRoute = userWithoutDashboard ? routes.plans : routes.dashboard;

  return (
    <Routes>
      <Route path={routes.main} element={<MainLayout />}>
        {allowedRoutes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route path="*" element={<Navigate to={defaultRoute} replace />} />
      </Route>
    </Routes>
  );
}
