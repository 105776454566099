import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { nodeAPI } from 'services/NodeService';

import BreadCrumbs from 'components/BreadCrumbs';
import Refetch from 'components/Refetch';
import Spinner from 'components/Spinner';

import TreeNodeFolders from './TreeNode/components/TreeNodeFolders';
import TreeNodeServiceUnits from './TreeNode/components/TreeNodeServiceUnits';

export default function DocumentsPage() {
  const { id } = useParams();
  const nodeId = Number(id);
  const { data: node, isLoading, refetch } = nodeAPI.useGetNodeByIdQuery(nodeId);

  // TODO
  useEffect(() => {
    const main = document.getElementsByTagName('main');
    if (main) {
      main[0].style.paddingTop = '0';
    }
    return () => {
      main[0].style.paddingTop = '16px';
    };
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  if (!node) {
    return <Refetch refetch={refetch} />;
  }

  const { children, path, hasServiceUnits } = node;

  const folders = children
    ?.map(x => ({ ...x, key: x.id }))
    .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0));
  return (
    <>
      {path && (
        <div className="sticky inset-x-0 top-0 z-50 flex w-full items-center gap-2 border-0 border-b border-solid border-border bg-quaternary px-2 py-[14px] text-primary">
          <BreadCrumbs path={path} />
        </div>
      )}
      <div className="min-h-[calc(100%_-_52px)] bg-white py-0">
        {/*TODO Folders*/}
        {children && children?.length > 0 && <TreeNodeFolders folders={folders ?? []} />}

        {/*TODO Service Units*/}
        {hasServiceUnits && <TreeNodeServiceUnits node={node} />}
      </div>
    </>
  );
}
