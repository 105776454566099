import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LoadingState {
  isLoading: boolean;
  files: File[];
  progress: number;
}

const initialState: LoadingState = {
  isLoading: false,
  files: [],
  progress: 0,
};

export const loadingSlice = createSlice({
  name: 'loadingSlice',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setFiles: (state, action: PayloadAction<File[]>) => {
      state.files = action.payload;
    },
    setProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    reset: () => initialState,
  },
});

export const { setIsLoading, setFiles, setProgress, reset } = loadingSlice.actions;

export default loadingSlice.reducer;
