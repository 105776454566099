import { ThemeConfig } from 'antd';

export const theme: ThemeConfig = {
  components: {
    Typography: {
      fontWeightStrong: 500,
      fontSizeHeading4: 14,
      lineHeightHeading4: 2,
      fontSizeHeading5: 14,
      lineHeightHeading5: 1.5,
      titleMarginBottom: 0,
      // sizeMarginHeadingVerticalEnd: 0,
    },
    Divider: {
      margin: 0,
    },
    Descriptions: {
      titleMarginBottom: 8,
    },
    Button: {
      defaultColor: '#061172',
    },
    Tabs: {
      titleFontSize: 32,
      lineHeight: 1,
      colorPrimary: '#1677FF',
      colorText: '#666666',
    },
  },
  token: {
    colorPrimaryBg: '#F5F5F5',
    fontFamily: 'IBM Plex Sans',
  },
};
