import { GetParams } from 'types/global';
import getBlobFile from 'utils/getBlobFile';

import api from './createRequest';

export default async function getAxiosFileByUrl(url: string, params?: GetParams) {
  try {
    const response = await api.get<Blob>(url, { responseType: 'blob', params });
    await getBlobFile(response);
  } catch (e) {
    console.error(e);
  }
}
