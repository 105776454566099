export default function removeEmptyKeys(obj: object) {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));
}

export const mapForParams = <T>(key: string, list: T[]) =>
  list.reduce(
    (prev, item, index) => ({
      ...prev,
      [`${key}[${index}]`]: item,
    }),
    {},
  );
