import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CommonInfoResponse,
  CreateServiceProductRequest,
  CreateServiceUnitRequest,
  FileResponse,
  ServiceProductResponse,
  ServiceUnitResponse,
  SlimServiceUnitResponsePaginationResponse,
  TagResponse,
  UpdateServiceUnitRequest,
} from 'types';
import { GetParams } from 'types/global';

import { baseQuery } from './baseQuery';

export const serviceUnitApi = createApi({
  reducerPath: 'serviceUnitApi',
  baseQuery: baseQuery,
  tagTypes: ['ServiceUnits', 'ServiceUnit', 'ServiceProducts', 'CommonInfo', 'EquipmentGroups', 'Manufacturers'],
  endpoints: builder => ({
    getAllServiceUnits: builder.query<SlimServiceUnitResponsePaginationResponse, GetParams>({
      query: params => {
        return {
          url: 'ServiceUnits',
          params,
        };
      },
      providesTags: () => ['ServiceUnits'],
    }),
    getCommonInfo: builder.query<CommonInfoResponse, void>({
      query: () => ({
        url: 'ServiceUnits/commonInfo',
      }),
      providesTags: () => ['CommonInfo'],
    }),
    getEquipmentGroupsByName: builder.query<TagResponse[], string>({
      query: name => ({
        url: 'ServiceUnits/equipmentGroups',
        params: {
          name,
        },
      }),
      providesTags: () => ['EquipmentGroups'],
    }),
    getById: builder.query<ServiceUnitResponse, number>({
      query: id => ({
        url: `ServiceUnits/${id}`,
      }),
      providesTags: () => ['ServiceUnit'],
    }),
    getServiceProductByName: builder.query<{ id: number; name: string }[], string>({
      query: name => ({
        url: 'ServiceUnits/serviceProducts',
        params: {
          name,
        },
      }),
      providesTags: () => ['ServiceProducts'],
    }),
    getManufacturerByName: builder.query<{ id: number; name: string }[], string>({
      query: name => ({
        url: 'ServiceUnits/manufacturers',
        params: {
          name,
        },
      }),
      providesTags: () => ['Manufacturers'],
    }),
    createServiceUnit: builder.mutation<ServiceUnitResponse, CreateServiceUnitRequest>({
      query: form => ({
        url: 'ServiceUnits',
        method: 'POST',
        body: form,
      }),
      invalidatesTags: ['ServiceUnits'],
    }),
    createServiceProduct: builder.mutation<ServiceProductResponse, CreateServiceProductRequest>({
      query: form => ({
        url: 'ServiceUnits/ServiceProduct',
        method: 'POST',
        body: form,
      }),
      invalidatesTags: ['ServiceProducts'],
    }),
    updateServiceUnit: builder.mutation<ServiceUnitResponse, { id: number; body: UpdateServiceUnitRequest }>({
      query: ({ id, body }) => ({
        url: `ServiceUnits/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['ServiceUnit'],
    }),
    uploadServiceUnitFiles: builder.mutation<FileResponse, { id: number; form: FormData }>({
      query: ({ id, form }) => ({
        url: `ServiceUnits/${id}/files`,
        method: 'POST',
        body: form,
      }),
      invalidatesTags: ['ServiceUnit'],
    }),
    deleteFile: builder.mutation<number, { id: number; fileId: number }>({
      query: ({ id, fileId }) => ({
        url: `ServiceUnits/${id}/files/${fileId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ServiceUnit'],
    }),
  }),
});
