import React, { Key, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tree } from 'antd';
import type { DirectoryTreeProps } from 'antd/es/tree';
import { nodeAPI } from 'services/NodeService';
import { ReactComponent as DirectorySvg } from 'svg/directory.svg';
import mapNodes from 'utils/mapNodes';

import Spinner from 'components/Spinner';

const { DirectoryTree } = Tree;

export default function Directory() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);

  const { data, isLoading } = nodeAPI.useGetRootNodesQuery();

  useEffect(() => {
    if (id) {
      setExpandedKeys(prevState => Array.from(new Set([...prevState, Number(id)])));
    }
  }, [id]);

  const onSelect: DirectoryTreeProps['onSelect'] = keys => {
    const nodeId = keys[0];
    navigate(`/documents/${nodeId}`);
  };

  if (!data || isLoading) {
    return <Spinner />;
  }

  return (
    <DirectoryTree
      // autoExpandParent
      className="typography-caption-text h-full overflow-y-scroll font-bold text-primary"
      onSelect={onSelect}
      onExpand={keys => setExpandedKeys(keys)}
      expandedKeys={expandedKeys}
      treeData={mapNodes(data)}
      selectedKeys={[Number(id)]}
      icon={<DirectorySvg />}
    />
  );
}
