import React from 'react';
import { NavLink, To } from 'react-router-dom';
import clsx from 'clsx';

interface Props {
  to: To;
  children: React.ReactNode;
}

export default function NavbarMenuItem({ children, to }: Props) {
  return (
    <li>
      <NavLink
        to={to}
        className={({ isActive }) =>
          clsx(
            'flex items-center gap-1 px-[34px] text-third laptop:px-3 tablet:px-[0.875rem]',
            isActive && 'text-primary',
          )
        }
      >
        {children}
      </NavLink>
    </li>
  );
}
