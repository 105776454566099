import { TFunction } from 'i18next';
import { PlanPeriod } from 'types';
import { Option } from 'types/global';
import { monthFormat, yearFormat } from 'utils/constants';

const CS = 'CS';
const LP = 'LP';

const optionsPhase: Option[] = [
  {
    value: CS,
    label: CS,
  },
  {
    value: LP,
    label: LP,
  },
];

const getOptionsPeriod = (t: TFunction<any, undefined>): Option[] => {
  return [
    { value: PlanPeriod.Y, label: t('Year') },
    { value: PlanPeriod.M, label: t('Month') },
    { value: PlanPeriod.W, label: t('Week') },
  ];
};
const getPeriodPicker = (planPeriod: PlanPeriod | undefined) => {
  switch (planPeriod) {
    case PlanPeriod.Y:
      return 'year';
    case PlanPeriod.M:
      return 'month';
    case PlanPeriod.W:
      return 'week';
    default:
      return undefined;
  }
};

const getPeriodFormat = (planPeriod: PlanPeriod | undefined) => {
  switch (planPeriod) {
    case PlanPeriod.Y:
      return yearFormat;
    case PlanPeriod.M:
      return monthFormat;
    case PlanPeriod.W:
      return undefined;
    default:
      return undefined;
  }
};

export { getOptionsPeriod, getPeriodFormat, getPeriodPicker, optionsPhase };
