import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ReactComponent as DirectorySvg } from 'svg/directory.svg';
import type { TreeNodeResponse } from 'types';

interface TreeNodeFoldersProps {
  folders: TreeNodeResponse[];
}

const TreeNodeFolders = ({ folders }: TreeNodeFoldersProps) => {
  const folderColumns: ColumnsType<TreeNodeResponse> = [
    {
      title: '',
      dataIndex: 'icon',
      key: 'icon',
      render: () => <DirectorySvg className="ml-7" />,
      width: 16,
      className: '!p-1 [&&&]:bg-white [&&&]:border-b-0',
      onHeaderCell: () => ({ className: '[&&&]:border-b [&&&]:border-border [&&&]:rounded-none' }),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0),
      render: (_, folder) => (
        <Link to={`/documents/${folder.id}`} className="typography-caption-text font-bold  text-primary">
          {folder.name}
        </Link>
      ),
      className: '[&&&]:bg-white [&&&]:text-primary [&&&]:border-b-0',
      onHeaderCell: () => ({ className: '[&&&]:border-b [&&&]:border-border [&&&]:rounded-none' }),
    },
  ];
  return (
    <Table
      size="small"
      className="mb-4"
      dataSource={folders}
      columns={folderColumns}
      pagination={false}
      expandIconColumnIndex={-1}
      bordered={false}
    />
  );
};

export default TreeNodeFolders;
