import getAxiosFileByUrl from 'api/getAxiosFileByUrl';
import dayjs, { OpUnitType } from 'dayjs';
import { beRoutes } from 'router/routes';
import { EmergencyTaskAction, EmergencyTaskActionEnumResponse, EmergencyTaskGlobalStatus, PlanPeriod } from 'types';
import { Option } from 'types/global';
import camelCaseToSpace from 'utils/camelCaseToSpace';
import { requestDateFormat } from 'utils/constants';
import removeEmptyKeys, { mapForParams } from 'utils/removeEmptyKeys';

const pageSize = 10000;

export const initialParams = {
  pageSize,
  isAwaitingCompanyAction: false,
  isAwaitingUserAction: false,
  onlyAcceptedOffer: false,
  onlyCreatedFromUnscheduledTask: false,
};

export interface EmergencyTaskSearchFormValues {
  companiesIds: number[];
  statuses: EmergencyTaskGlobalStatus[];
  subjectsIds: number[];
  isAwaitingUserAction: boolean;
  isAwaitingCompanyAction: boolean;
  onlyAcceptedOffer: boolean;
  onlyCreatedFromUnscheduledTask: boolean;
  userIds: number[];
  // date: dayjs.Dayjs[];
  date: Date | undefined;
}

export const filterOption = (input: string, option: Option | undefined) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase().trim());

export const initialValues: EmergencyTaskSearchFormValues = {
  companiesIds: [],
  statuses: [],
  subjectsIds: [],
  isAwaitingUserAction: false,
  isAwaitingCompanyAction: false,
  onlyAcceptedOffer: false,
  onlyCreatedFromUnscheduledTask: false,
  userIds: [],
  // date: [getNowDayJs().date(1), getNowDayJs()],
  date: undefined,
};

const getLabel = (action: EmergencyTaskAction, enums: EmergencyTaskActionEnumResponse[]) => {
  const current = enums.find(x => x.value === action);
  if (current) {
    return current.label;
  }
  return camelCaseToSpace(action);
};

const FILES_REQUIRED_ACTIONS = [
  EmergencyTaskAction.MakeOffer,
  EmergencyTaskAction.GoToAddMaterials,
  EmergencyTaskAction.GoToChangePerformer,
  EmergencyTaskAction.GoToUseSpareParts,
  // EmergencyTaskAction.FormABrigade,
  EmergencyTaskAction.SendReportForConfirmationToResponsible,
  // EmergencyTaskAction.SendReportForConfirmationToAuthor,
  EmergencyTaskAction.SetPlannedReceiptDateForPurchasedMaterials,
];

const filesRequired = (action: EmergencyTaskAction | null) =>
  Boolean(action && FILES_REQUIRED_ACTIONS.includes(action));

export { filesRequired, getLabel };

const getPeriodPicker = (planPeriod: PlanPeriod | undefined) => {
  switch (planPeriod) {
    case PlanPeriod.Y:
      return 'year';
    case PlanPeriod.M:
      return 'month';
    case PlanPeriod.W:
      return 'week';
    default:
      return undefined;
  }
};

export const convertValuesToParams = (values: EmergencyTaskSearchFormValues, period: PlanPeriod) => {
  const {
    date,
    isAwaitingUserAction,
    isAwaitingCompanyAction,
    onlyAcceptedOffer,
    onlyCreatedFromUnscheduledTask,
    companiesIds: _companiesIds,
    statuses: _statuses,
    subjectsIds: _subjectsIds,
    userIds: _userIds,
  } = values;

  const _Date = date ?? new Date();

  const _startEndOf = getPeriodPicker(period) as OpUnitType;

  const from = _startEndOf && dayjs(_Date).startOf(_startEndOf).format(requestDateFormat);
  const to = _startEndOf && dayjs(_Date).endOf(_startEndOf).format(requestDateFormat);

  const [subjectsIdsFiltered, subjectWithChildIdsFiltered] = _subjectsIds.reduce(
    (acc, curr) => {
      // Сортируем выбранные на задачи + задач с подзадачами в зависимости от знака ID
      // после сортировки сохраняем значение ID по модулю
      acc[curr < 0 ? 1 : 0].push(Math.abs(curr));
      return acc;
    },
    [[], []] as [number[], number[]],
  );

  const companiesIds = mapForParams('companiesIds', _companiesIds);
  const subjectsIds = mapForParams('subjectsIds', subjectsIdsFiltered);
  const subjectWithChildIds = mapForParams('subjectWithChildIds', subjectWithChildIdsFiltered);
  const statuses = mapForParams('statuses', _statuses);
  const userIds = mapForParams('userIds', _userIds);
  const res = removeEmptyKeys({
    from,
    to,
    isAwaitingUserAction,
    isAwaitingCompanyAction,
    onlyAcceptedOffer,
    onlyCreatedFromUnscheduledTask,
    ...companiesIds,
    ...statuses,
    ...subjectsIds,
    ...subjectWithChildIds,
    ...userIds,
  });

  return { ...initialParams, ...res };
};

export const downloadCard = async (id: number) => {
  try {
    const url = beRoutes.emergencyTasks + '/' + id + '/' + 'card';
    await getAxiosFileByUrl(url);
  } catch (e) {
    console.error(e);
  }
};
