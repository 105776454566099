import { createApi } from '@reduxjs/toolkit/query/react';
import {
  Ordering,
  SlimUnscheduledTaskResponsePaginationResponse,
  UnscheduledTaskResponse,
  UnscheduledTaskUpdateRequest,
} from 'types';

import { baseQuery } from './baseQuery';

export interface UnscheduledTasksRequest {
  subjectsIds?: number[];
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  columnName?: string;
  ordering?: Ordering;
  from?: string;
  to?: string;
}

export const unscheduledTaskApi = createApi({
  reducerPath: 'unscheduledTaskApi',
  baseQuery: baseQuery,
  tagTypes: ['UnscheduledTasks', 'UnscheduledTask'],
  endpoints: builder => ({
    getAll: builder.query<SlimUnscheduledTaskResponsePaginationResponse, UnscheduledTasksRequest | undefined>({
      query: params => {
        return {
          url: 'UnscheduledTasks',
          params: params,
        };
      },
      providesTags: () => ['UnscheduledTasks'],
    }),
    getById: builder.query<UnscheduledTaskResponse, number>({
      query: id => ({
        url: `UnscheduledTasks/${id}`,
      }),
      providesTags: () => ['UnscheduledTask'],
    }),
    updateUnscheduledTask: builder.mutation<
      UnscheduledTaskResponse,
      {
        id: number;
        body: UnscheduledTaskUpdateRequest;
      }
    >({
      query: ({ id, body }) => ({
        url: `UnscheduledTasks/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: ['UnscheduledTask', 'UnscheduledTasks'],
    }),
  }),
});
