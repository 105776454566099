import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, message } from 'antd';
import { nodeAPI } from 'services/NodeService';

interface BreadCrumbsProps {
  path: string;
}

const BreadCrumbs = ({ path }: BreadCrumbsProps) => {
  const navigate = useNavigate();
  const [getNodesByPath] = nodeAPI.useLazyGetNodesQuery();

  const paths = path.split('/');
  const handleClick = (index: number) => {
    const nodePath = paths.slice(0, index + 1).join('/');
    getNodesByPath({ nodePath })
      .unwrap()
      .then(node => {
        navigate(`/documents/${node.id}`);
      });
  };
  const handleCopy = async (path: string) => {
    try {
      await navigator.clipboard.writeText(path);
      message.success('Copied!');
    } catch (e) {
      console.error(e);
      message.error('Something went wrong!');
    }
  };

  const items = paths.map((path, index) => ({
    title: (
      <Link className="font-medium [&&&]:h-auto [&&&]:text-primary" to="">
        {path}
      </Link>
    ),
    onClick: () => handleClick(index),
    className: 'typography-caption-text',
  }));

  return (
    <div className="flex items-center gap-2">
      <Breadcrumb separator={<span className="font-medium text-primary">/</span>} items={items} />
      <Button icon={<CopyOutlined />} size="small" onClick={() => handleCopy(path)} />
    </div>
  );
};

export default BreadCrumbs;
