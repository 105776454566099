import { createApi } from '@reduxjs/toolkit/query/react';
import {
  EmergencyTaskActionEnumResponse,
  EmergencyTaskStatusEnumResponse,
  MeasuringUnitEnumResponse,
  ScheduledTaskActionEnumResponse,
  ScheduledTaskStatusEnumResponse,
  ScheduledWorkOrderActionEnumResponse,
  ScheduledWorkOrderGlobalStatusEnumResponse,
  ScheduledWorkOrderStatusEnumResponse,
  ScheduledWorkPeriodWebModelEnumResponse,
} from 'types';

import { baseQuery } from './baseQuery';

export const enumApi = createApi({
  reducerPath: 'enumApi',
  baseQuery: baseQuery,
  tagTypes: [
    'EmergencyTaskAction',
    'EmergencyTaskStatus',
    'ScheduledTaskAction',
    'ScheduledTaskStatus',
    'ScheduledWorkOrderAction',
    'ScheduledWorkOrderStatus',
    'ScheduledWorkOrderGlobalStatus',
    'MeasuringUnits',
    'ScheduledWorkPeriods',
  ],
  endpoints: builder => ({
    getAllEmergencyTaskActions: builder.query<EmergencyTaskActionEnumResponse[], void>({
      query: () => ({
        url: 'Enums/emergencyTaskAction',
      }),
      providesTags: () => ['EmergencyTaskAction'],
    }),
    getAllEmergencyTaskStatuses: builder.query<EmergencyTaskStatusEnumResponse[], void>({
      query: () => ({
        url: 'Enums/emergencyTaskStatus',
      }),
      providesTags: () => ['EmergencyTaskStatus'],
    }),
    getAllScheduledTaskActions: builder.query<ScheduledTaskActionEnumResponse[], void>({
      query: () => ({
        url: 'Enums/ScheduledTaskAction',
      }),
      providesTags: () => ['ScheduledTaskAction'],
    }),
    getAllScheduledTaskStatuses: builder.query<ScheduledTaskStatusEnumResponse[], void>({
      query: () => ({
        url: 'Enums/ScheduledTaskStatus',
      }),
      providesTags: () => ['ScheduledTaskStatus'],
    }),
    getAllScheduledWorkOrderActions: builder.query<ScheduledWorkOrderActionEnumResponse[], void>({
      query: () => ({
        url: 'Enums/ScheduledWorkOrderAction',
      }),
      providesTags: () => ['ScheduledWorkOrderAction'],
    }),
    getAllScheduledWorkOrderStatuses: builder.query<ScheduledWorkOrderStatusEnumResponse[], void>({
      query: () => ({
        url: 'Enums/ScheduledWorkOrderStatus',
      }),
      providesTags: () => ['ScheduledWorkOrderStatus'],
    }),
    getAllScheduledWorkOrderGlobalStatuses: builder.query<ScheduledWorkOrderGlobalStatusEnumResponse[], void>({
      query: () => ({
        url: 'Enums/ScheduledWorkOrderGlobalStatus',
      }),
      providesTags: () => ['ScheduledWorkOrderGlobalStatus'],
    }),
    getMeasuringUnits: builder.query<MeasuringUnitEnumResponse[], void>({
      query: () => ({
        url: 'Enums/MeasuringUnits',
      }),
      providesTags: () => ['MeasuringUnits'],
    }),
    getScheduledWorkPeriods: builder.query<ScheduledWorkPeriodWebModelEnumResponse[], void>({
      query: () => ({
        url: 'Enums/ScheduledWorkPeriods',
      }),
      providesTags: () => ['ScheduledWorkPeriods'],
    }),
  }),
});
