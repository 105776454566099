import { FileExtension } from 'types';
import { RuleProps } from 'utils/rules';

export enum Language {
  en = 'en',
  sr = 'sr',
  ru = 'ru',
}

export type Option = {
  value: number | string;
  label: string;
  disabled?: boolean;
};

export type NumberOption = {
  value: number;
  label: string;
};

export type AppFile = {
  id: number;
  name: string;
  extension: FileExtension;
  type?: string;
};

const GRAY = '#9ca3af';
const YELLOW = '#facc15';
const ORANGE = '#fb923c';
const GREEN = '#4ade80';

export enum EmergencyTaskStatusColor {
  Pending = GRAY,
  InWork = YELLOW,
  WaitingForAccept = ORANGE,
  Closed = GREEN,
}

export interface FormListField {
  name: string;
  placeholder?: string;
  type: 'input' | 'select' | 'selectCompany' | 'selectTechnique' | 'selectUser' | 'textarea' | 'datePicker';
  rules: RuleProps[];
}

export interface FormListProps {
  name: string;
  label: string;
  button: string;
  fields: FormListField[];
}

export interface ValueOption {
  value: string;
}

export type GetParams = Record<string, unknown>;

export enum TaskType {
  EmergencyTask = 'EmergencyTask',
  PlannedTask = 'PlannedTask',
}
