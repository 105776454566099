import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CompanyRoleTypeEnumResponse,
  CreateUserRequest,
  LoginRequest,
  LoginResponse,
  NotificationResponse,
  NotificationResponsePaginationResponse,
  UpdateNotificationRequest,
  UpdateUserRequest,
  UserResponse,
} from 'types';

import { baseQuery } from './baseQuery';

const PageSize = 50;

interface GetNotificationsParams {
  PageSize?: number;
  PageNumber?: number;
}

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQuery,
  tagTypes: ['User', 'Users', 'Roles', 'CurrentUser', 'Token', 'Notifications'],
  endpoints: builder => ({
    loginUser: builder.mutation<LoginResponse, LoginRequest>({
      query: ({ login, password }) => ({
        url: 'Auth/login',
        method: 'POST',
        body: { login, password },
      }),
      invalidatesTags: ['Token'],
    }),
    // refreshTokens: builder.mutation<LoginResponse, RefreshTokenRequest>({
    //   query: ({accessToken, refreshToken}) => ({
    //     url: `Auth/refresh`,
    //     method: 'POST',
    //     body: {accessToken, refreshToken}
    //   }),
    //   invalidatesTags: ['Token']
    // }),
    getCurrentUser: builder.query<UserResponse, void>({
      query: () => ({
        url: 'Users/current',
      }),
      providesTags: () => ['CurrentUser'],
    }),
    getAllUsers: builder.query<UserResponse[], void>({
      query: () => ({
        url: 'Users',
      }),
      providesTags: () => ['Users'],
    }),
    getActiveUsers: builder.query<UserResponse[], void>({
      query: () => ({
        url: 'Users',
      }),
      providesTags: () => ['Users'],
      transformResponse: (users: UserResponse[]) => users.filter(({ activityStatus }) => activityStatus),
    }),
    getUserById: builder.query<UserResponse, number>({
      query: id => ({
        url: `Users/${id}`,
      }),
      providesTags: () => ['User'],
    }),
    getRoles: builder.query<CompanyRoleTypeEnumResponse[], void>({
      query: () => ({
        url: 'Users/roles',
      }),
      providesTags: () => ['Roles'],
    }),
    getNotifications: builder.query<NotificationResponsePaginationResponse, GetNotificationsParams | void>({
      query: params => ({
        url: 'Users/notifications',
        params: {
          PageSize,
          ...params,
        },
      }),
      keepUnusedDataFor: 0,
      providesTags: () => ['Notifications'],
    }),
    updateNotification: builder.mutation<NotificationResponse, { id: number; body: UpdateNotificationRequest }>({
      query: ({ id, body }) => ({
        url: `Users/notifications/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Notifications'],
    }),
    readAllNotification: builder.mutation<NotificationResponse, void>({
      query: () => ({
        url: 'Users/notifications/viewed',
        method: 'PUT',
        body: new FormData(),
      }),
      invalidatesTags: ['Notifications'],
    }),
    createUser: builder.mutation<UserResponse, CreateUserRequest>({
      query: user => ({
        url: 'Users',
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['Users'],
    }),
    updateUser: builder.mutation<UserResponse, { id: number; body: UpdateUserRequest }>({
      query: ({ id, body }) => ({
        url: `Users/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    confirmEmailAddress: builder.mutation<UserResponse, number>({
      query: code => ({
        url: 'Users/confirmEmailAddress',
        method: 'POST',
        params: { code },
      }),
      invalidatesTags: ['User', 'CurrentUser'],
    }),
  }),
});
