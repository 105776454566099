import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, theme, Tooltip } from 'antd';
import Sider from 'antd/es/layout/Sider';
import useToggle from 'hooks/useToggle';

import Directory from './Directory';

export default function TreeSider() {
  const [collapsed, setCollapsed] = useToggle(true);
  const {
    token: { colorFillContent },
  } = theme.useToken();

  return (
    <Sider
      className="relative h-[calc(100vh-56px)] p-4"
      style={{ background: colorFillContent }}
      width={424}
      collapsedWidth={0}
      collapsed={collapsed}
    >
      <Directory />
      <Tooltip title={'show/collapse sider'}>
        <Button
          className="absolute right-0 top-1/2 z-10 h-24 !w-4 -translate-y-1/2"
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={setCollapsed}
        />
      </Tooltip>
    </Sider>
  );
}
