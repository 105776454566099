import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Badge, message, Tooltip } from 'antd';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setFresh, setUnread } from 'redux/mainSlice';
import { routes } from 'router/routes';
import { userApi } from 'services/UserService';
import { ReactComponent as BellIcon } from 'svg/bell.svg';

import Spinner from 'components/Spinner';

export default function NavbarNotification() {
  const { t } = useTranslation('main-panel');
  const [messageApi, contextHolder] = message.useMessage({ top: 64 });
  const dispatch = useAppDispatch();
  const { accessToken, unread, fresh } = useAppSelector(state => state.mainSlice);
  const hubConnection = useRef<HubConnection>();

  const [fetchNotifications, { isLoading }] = userApi.useLazyGetNotificationsQuery();

  useEffect(() => {
    hubConnection.current = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_BACKEND}/notification?access_token=${accessToken}`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Information)
      .build();
    hubConnection.current?.start();

    hubConnection.current.on('Notify', () =>
      // res: NotProps
      {
        // console.warn('web-socket-notification', res);
        dispatch(setUnread(true));
        dispatch(setFresh(true));
        // messageApi.warning('You have new notification!');
        setTimeout(() => dispatch(setFresh(false)), 5000);
      },
    );
    // hubConnection.current.on('activeusers', (res: NotProps) => {
    // console.warn('activeusers', res);
    // dispatch(setUnread(true))
    // dispatch(setFresh(true))
    // setTimeout(() => dispatch(setFresh(false)), 5000)
    // });
    return () => {
      hubConnection.current?.stop();
    };
  }, []);

  useEffect(() => {
    fetchNotifications()
      .unwrap()
      .then(({ data: _notifications }) => {
        if (_notifications && _notifications.length && _notifications.find(x => x.viewed === false)) {
          dispatch(setUnread(true));
          messageApi.warning(
            `You have ${_notifications.filter(x => x.viewed === false).length} unread notification(s)!`,
            1,
          );
        }
      });
  }, [fetchNotifications, dispatch, messageApi]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {contextHolder}
      <Link className="flex items-center" to={routes.notifications}>
        <Tooltip title={t('Notifications')}>
          <Badge classNames={{ indicator: '[&&&]:shadow-none [&&&]:top-[3px] [&&&]:right-[3px]' }} dot={unread}>
            <BellIcon className={clsx(fresh && 'bell', 'text-base')} />
          </Badge>
        </Tooltip>
      </Link>
    </>
  );
}
