import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import DocumentsPage from 'pages/DocumentsPage/DocumentsPage';
import { routes } from 'router/routes';

const DashboardPage = lazy(() => import('pages/DashboardPage/DashboardPage'));
const NotificationsPage = lazy(() => import('pages/NotificationsPage/NotificationsPage'));
const ProfilePage = lazy(() => import('pages/ProfilePage/ProfilePage'));
const WebsocketPage = lazy(() => import('pages/WebsocketPage/WebsocketPage'));
const CompaniesPage = lazy(() => import('pages/CompaniesPage/CompaniesPage'));
const EmergencyTask = lazy(() => import('pages/EmergencyTaskPage/EmergencyTaskPage'));
const EmergencyTasks = lazy(() => import('pages/EmergencyTasksPage/EmergencyTasksPage'));
const LoginPage = lazy(() => import('pages/LoginPage/LoginPage'));
const ServiceUnitPage = lazy(() => import('pages/ServiceUnitPage'));
const UsersPage = lazy(() => import('pages/UsersPage/UsersPage'));
const HelpdeskPage = lazy(() => import('pages/HelpdeskPage/HelpdeskPage'));
const ScheduledWorkOrdersPage = lazy(() => import('pages/ScheduledWorkOrdersPage'));
const ScheduledWorkOrderPage = lazy(() => import('pages/ScheduledWorkOrderPage/ScheduledWorkOrderPage'));
const ScheduledTaskPage = lazy(() => import('pages/ScheduledTaskPage/ScheduledTaskPage'));
const PlansPage = lazy(() => import('pages/PlansPage/PlansPage'));
const LogsPage = lazy(() => import('pages/LogsPage/LogsPage'));
const DwgAndVendorPage = lazy(() => import('pages/DwgAndVendorPage'));
const ReportTasks = lazy(() => import('pages/ReportTasksPage/ReportTasksPage'));
const ScheduledWorks = lazy(() => import('pages/ScheduledWorks/ScheduledWorks'));
const TranslationTasksPage = lazy(() => import('pages/TranslationTasksPage'));

const privateRoutes: RouteProps[] = [
  {
    path: routes.dashboard,
    element: <DashboardPage />,
  },
  {
    path: routes.documents + '/:id',
    element: <DocumentsPage />,
  },
  {
    path: routes.users,
    element: <UsersPage />,
  },
  {
    path: routes.companies,
    element: <CompaniesPage />,
  },
  {
    path: routes.profile,
    element: <ProfilePage />,
  },
  {
    path: routes.emergencyTasks,
    element: <EmergencyTasks />,
  },
  {
    path: routes.reportTasks,
    element: <ReportTasks />,
  },
  {
    path: routes.emergencyTasks + '/:id',
    element: <EmergencyTask />,
  },
  {
    path: routes.serviceUnit + '/:id',
    element: <ServiceUnitPage />,
  },
  {
    path: routes.helpdesk,
    element: <HelpdeskPage />,
  },
  {
    path: routes.profile,
    element: <ProfilePage />,
  },
  {
    path: routes.websocket,
    element: <WebsocketPage />,
  },
  {
    path: routes.notifications,
    element: <NotificationsPage />,
  },
  {
    path: routes.scheduledWorkOrders,
    element: <ScheduledWorkOrdersPage />,
  },
  {
    path: routes.scheduledWorkOrders + '/:id',
    element: <ScheduledWorkOrderPage />,
  },
  {
    path: routes.scheduledWorkOrders + '/:scheduledWorkOrderId' + routes.scheduledTasks + '/:scheduledTaskId',
    element: <ScheduledTaskPage />,
  },
  {
    path: routes.plans,
    element: <PlansPage />,
  },
  {
    path: routes.logs,
    element: <LogsPage />,
  },
  {
    path: routes.dwgAndVendor,
    element: <DwgAndVendorPage />,
  },
  {
    path: routes.main,
    element: <DashboardPage />,
  },
  {
    path: routes.scheduledWorks,
    element: <ScheduledWorks />,
  },
];

const translatorRoutes: RouteProps[] = [
  {
    path: routes.main,
    element: <TranslationTasksPage />,
  },
  {
    path: routes.translationTask,
    element: <TranslationTasksPage />,
  },
];

const generalContractorOverwriteRoutes: RouteProps[] = [
  {
    path: routes.main,
    element: <PlansPage />,
  },
];

const publicRoutes: RouteProps[] = [
  {
    path: routes.main,
    element: <LoginPage />,
  },
];

export { generalContractorOverwriteRoutes, privateRoutes, publicRoutes, translatorRoutes };
