import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { LogParamsProps } from 'pages/LogsPage/utils';
import {
  CompanyThatHaveTasksResponse,
  ContractorsStatisticResponse,
  EmergencyTaskStatisticResponse,
  FileLogResponsePaginationResponse,
  LoadByTasksStatisticResponse,
  ScheduledWorkOrderStatisticInfoResponse,
  StatisticInfoResponse,
  UnscheduledTaskStatisticResponse,
  UpdateStatisticInfoRequest,
} from 'types';
import { GetParams } from 'types/global';

import { baseQuery } from './baseQuery';

export const statisticsAPI = createApi({
  reducerPath: 'statisticsAPI',
  baseQuery: baseQuery,
  tagTypes: ['Statistics'],
  refetchOnMountOrArgChange: true,
  endpoints: build => ({
    getStatistics: build.query<StatisticInfoResponse[], void>({
      query: () => ({
        url: '/Statistics',
      }),
      providesTags: () => ['Statistics'],
    }),
    updateStatistics: build.mutation<
      StatisticInfoResponse,
      { statisticInfoId: number; body: UpdateStatisticInfoRequest }
    >({
      query: ({ statisticInfoId, body }) => ({
        url: `/Statistics/${statisticInfoId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Statistics'],
    }),
    getScheduledWorkOrders: build.query<ScheduledWorkOrderStatisticInfoResponse, GetParams>({
      query: params => ({
        url: '/Statistics/scheduledWorkOrders',
        params,
      }),
      providesTags: () => ['Statistics'],
    }),
    getEmergencyTasks: build.query<EmergencyTaskStatisticResponse, GetParams>({
      query: params => ({
        url: '/Statistics/EmergencyTasks',
        params,
      }),
      providesTags: () => ['Statistics'],
    }),
    getUnscheduledTasks: build.query<UnscheduledTaskStatisticResponse, GetParams>({
      query: params => ({
        url: '/Statistics/unscheduledTasks',
        params,
      }),
      providesTags: () => ['Statistics'],
    }),
    getContractors: build.query<ContractorsStatisticResponse, GetParams>({
      query: params => ({
        url: '/Statistics/Contractors',
        params,
      }),
      providesTags: () => ['Statistics'],
    }),
    getLoadByTasks: build.query<LoadByTasksStatisticResponse, GetParams>({
      query: params => ({
        url: '/Statistics/LoadByTasks',
        params,
      }),
      providesTags: () => ['Statistics'],
    }),
    getCompanies: build.query<CompanyThatHaveTasksResponse[], GetParams>({
      query: params => ({
        url: '/Statistics/companies',
        params,
      }),
      providesTags: () => ['Statistics'],
    }),
    getFilesLog: build.query<FileLogResponsePaginationResponse, LogParamsProps>({
      query: params => ({
        url: '/Statistics/filesLog',
        params: {
          PageSize: 50,
          ...params,
        },
      }),
      providesTags: () => ['Statistics'],
    }),
  }),
});
