import axios from 'axios';

const logout = () => {
  //handle logout
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND + '/api/v1',
});

api.interceptors.request.use(config => {
  const accessToken = localStorage.getItem('accessToken');

  //checking if accessToken exists
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
});

api.interceptors.response.use(
  response => response,
  async error => {
    //extracting response and config objects
    const { response, config } = error;
    //checking if error is Unauthorized error
    if ([401, 302, 0].includes(response.status)) {
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');
      if (accessToken && refreshToken) {
        //if refresh token exists in local storage proceed
        try {
          //try refreshing token
          const { data: tokens } = await api.post('auth/refresh', {
            accessToken,
            refreshToken,
          });
          // let accessToken = data.data.accessToken;
          if (tokens && tokens.accessToken && tokens.refreshToken) {
            //if request is successful and token exists in response data
            //store it in local storage
            localStorage.setItem('accessToken', tokens.accessToken);
            localStorage.setItem('refreshToken', tokens.refreshToken);
            //with new token retry original request
            config.headers['Authorization'] = tokens.accessToken;
            return api(config);
          }
        } catch (e) {
          console.error(e);
          logout();
        }
      }
    }
    //clear local storage and log user out
    throw new Error(error);
  },
);

export default api;
