import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { CompanyResponse, CompanyRoleTypeEnumResponse, CreateCompanyRequest, UpdateCompanyRequest } from 'types';

import { baseQuery } from './baseQuery';

export const companyAPI = createApi({
  reducerPath: 'companyAPI',
  baseQuery: baseQuery,
  tagTypes: ['Company', 'Companies', 'Roles'],
  endpoints: build => ({
    getAll: build.query<CompanyResponse[], void>({
      query: () => ({
        url: '/Companies',
      }),
      providesTags: () => ['Companies'],
    }),
    getById: build.query<CompanyResponse, number>({
      query: id => ({
        url: `/Companies/${id}`,
      }),
      providesTags: () => ['Company'],
    }),
    getCompanyRoles: build.query<CompanyRoleTypeEnumResponse[], void>({
      query: () => ({
        url: '/Companies/roles',
      }),
      providesTags: () => ['Roles'],
    }),
    createCompany: build.mutation<CompanyResponse, CreateCompanyRequest>({
      query: company => ({
        url: '/Companies',
        method: 'POST',
        body: company,
      }),
      invalidatesTags: ['Companies'],
    }),
    updateCompany: build.mutation<CompanyResponse, { id: number; body: UpdateCompanyRequest }>({
      query: ({ id, body }) => ({
        url: `/Companies/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Companies'],
    }),
  }),
});
