import React, { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import PortalModal from 'pages/ServiceUnitPage/PortalModal';

type AppContextType = {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  modalChildren: React.ReactNode;
  setModalChildren: (modalChildren: React.ReactNode) => void;
  theme: 'dark' | 'light';
  setTheme: Dispatch<SetStateAction<'dark' | 'light'>>;
};

const appContextDefaultValues: AppContextType = {
  modal: false,
  setModal: () => {
    console.error('set modal context handler');
  },
  modalChildren: null,
  setModalChildren: () => {
    console.error('set modalChildren context handler');
  },
  theme: 'light',
  setTheme: () => {
    console.error('set theme context handler');
  },
};

export const AppContext = createContext<AppContextType>(appContextDefaultValues);

type AppProviderProps = {
  children: ReactNode;
};

export default function AppProvider({ children }: AppProviderProps) {
  const [modal, setModal] = useState(false);
  const [modalChildren, setModalChildren] = useState<React.ReactNode | null>(null);
  const [theme, setTheme] = useState<'dark' | 'light'>(
    (localStorage.getItem('prefers-color-scheme') as 'dark' | 'light' | null) ??
      // (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'),
      'light',
  );
  useEffect(() => {
    localStorage.setItem('prefers-color-scheme', theme);
  }, [theme]);

  return (
    <AppContext.Provider value={{ modal, setModal, modalChildren, setModalChildren, theme, setTheme }}>
      <PortalModal
        open={modal}
        onClose={() => {
          setModal(false);
          setModalChildren(null);
        }}
      >
        {modalChildren}
      </PortalModal>
      {children}
    </AppContext.Provider>
  );
}
