import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Language } from 'types/global';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setLanguage } from '../../redux/mainSlice';

import { LanguageItemProps } from './utils';

const list: LanguageItemProps[] = [
  {
    language: Language.sr,
    avatar: '/images/flags/sr.svg',
  },
  {
    language: Language.en,
    avatar: '/images/flags/en.svg',
  },
  {
    language: Language.ru,
    avatar: '/images/flags/ru.svg',
  },
];

type SelectLanguageProps = React.ComponentPropsWithoutRef<'ul'>;

export default function SelectLanguage({ className }: SelectLanguageProps) {
  return (
    <ul className={clsx(className, 'flex flex-col')}>
      {list.map(({ avatar, language }) => (
        <li key={language}>
          <LangItem language={language} avatar={avatar} />
        </li>
      ))}
    </ul>
  );
}

function LangItem({ language, avatar }: { language: Language; avatar: string }) {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const changeLanguage = useCallback(async () => {
    await i18n.changeLanguage(language);
    localStorage.setItem('language', language);
    dispatch(setLanguage(language));
  }, [dispatch, i18n, language]);
  const selectedLanguage = useAppSelector(state => state.mainSlice.language);

  return (
    <div className="flex h-12 w-12 items-center justify-center">
      <img
        alt={language}
        title={language}
        className={clsx(
          'cursor-pointer',
          selectedLanguage === language && 'outline outline-2 outline-primary drop-shadow-md',
        )}
        onClick={changeLanguage}
        src={avatar}
      />
    </div>
  );
}
