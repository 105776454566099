import clsx from 'clsx';
import useAppContext from 'hooks/useAppContext';
import { ReactComponent as MoonSvg } from 'svg/moon.svg';
import { ReactComponent as SunSvg } from 'svg/sun.svg';

export default function ThemeSwitcher({ className }: { className?: string }) {
  const { theme, setTheme } = useAppContext();
  const onChangeHandler = () => {
    setTheme(_prevState => (_prevState === 'light' ? 'dark' : 'light'));
  };

  return (
    <label
      className={clsx(
        className,
        'absolute',
        'w-[11rem]',
        'h-[3.625rem]',
        'flex',
        'items-center',
        'justify-stretch',
        'text-center',
        'rounded-full',
        'transition-all',
        'duration-300',
        'bg-white',
        'border',
        'border-solid',
        'cursor-pointer',
        {
          'border-[rgba(0, 0, 0, 0.50)]': theme === 'light',
          'border-transparent': theme === 'dark',
          'shadow-md': theme === 'light',
          'shadow-none': theme === 'dark',
        },
      )}
    >
      <input
        className="absolute appearance-none"
        type="checkbox"
        checked={theme === 'dark'}
        onChange={onChangeHandler}
      />
      <div
        className={clsx(
          'absolute',
          'w-12',
          'h-12',
          'flex',
          'items-center',
          'justify-center',
          'rounded-full',
          'transition-all',
          'duration-300',
          'border',
          'border-solid',
          {
            'left-1.5': theme === 'light',
            'right-1.5': theme === 'dark',
            'border-[rgba(0, 0, 0, 0.50)]': theme === 'light',
            'border-transparent': theme === 'dark',
            'bg-lightBlue': theme === 'light',
            'bg-darkPrimary': theme === 'dark',
          },
        )}
      >
        {theme === 'light' && <MoonSvg />}
        {theme === 'dark' && <SunSvg />}
      </div>
      {theme === 'light' && <div className="grow pl-12 text-base font-bold text-primary">Light mode</div>}
      {theme === 'dark' && <div className="grow pr-12 text-base font-bold text-primary">Dark mode</div>}
    </label>
  );
}
