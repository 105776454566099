import { useTranslation } from 'react-i18next';
import { ReactComponent as CompanyIcon } from 'assets/svg/company.svg';
import { ReactComponent as DashboardIcon } from 'assets/svg/dashboard.svg';
import { ReactComponent as EmergencyIcon } from 'assets/svg/emergency.svg';
import { ReactComponent as LogsIcon } from 'assets/svg/logs.svg';
import { ReactComponent as PlannedIcon } from 'assets/svg/planned.svg';
import { ReactComponent as PlansIcon } from 'assets/svg/plans.svg';
import { ReactComponent as ReportIcon } from 'assets/svg/report.svg';
import { ReactComponent as UserIcon } from 'assets/svg/user.svg';
import { ReactComponent as VendorIcon } from 'assets/svg/vendor.svg';
import useMediaQuery, { MQ } from 'hooks/useMediaQuery';
import { routes } from 'router/routes';
import { CompanyRoleType, UserResponse, UserRoleType } from 'types';

import NavbarMenuItemLink from './NavbarMenuItem';

interface NavbarMenuProps {
  user: UserResponse;
}

const NavbarMenu = ({ user }: NavbarMenuProps) => {
  const { t } = useTranslation('main-panel');
  const isTablet = useMediaQuery(MQ.tablet);
  const { role } = user;

  const isAdmin = role === UserRoleType.Admin;
  const isSubcontractor = role === UserRoleType.Subcontractor;
  const isTranslator = role === UserRoleType.Translator;
  const userWithoutDashboard =
    role === UserRoleType.GeneralContractor && user.company.role === CompanyRoleType.GeneralContractor;

  if (isTranslator) {
    return null;
  }

  return (
    <ul className="typography-caption-small mb-0 flex items-center font-medium text-third">
      {!userWithoutDashboard && (
        <NavbarMenuItemLink to={routes.dashboard}>
          <div className="flex h-10 w-10 items-center justify-center tablet:h-6 tablet:w-6">
            <DashboardIcon className="max-h-full" />
          </div>
          <span className="w-14">{t('Dashboard')}</span>
        </NavbarMenuItemLink>
      )}
      {!isSubcontractor && (
        <NavbarMenuItemLink to={routes.plans}>
          <div className="flex h-10 w-10 items-center justify-center tablet:h-6 tablet:w-6">
            <PlansIcon className="max-h-full" />
          </div>
          <span className="w-8">{t('Plans')}</span>
        </NavbarMenuItemLink>
      )}
      <NavbarMenuItemLink to={routes.scheduledWorkOrders}>
        <div className="flex h-10 w-10 items-center justify-center tablet:h-6 tablet:w-6">
          <PlannedIcon className="max-h-full" />
        </div>
        <span className="w-12 tablet:w-auto">
          {isTablet
            ? 'PT' // t('Planned tasks (short)')
            : t('Planned tasks')}
        </span>
      </NavbarMenuItemLink>
      <NavbarMenuItemLink to={routes.reportTasks}>
        <div className="flex h-10 w-10 items-center justify-center tablet:h-6 tablet:w-6">
          <ReportIcon className="max-h-full" />
        </div>
        <span className="w-16 tablet:w-auto">
          {isTablet
            ? 'RT' // t('Planned tasks (short)')
            : t('Report tasks')}
        </span>
      </NavbarMenuItemLink>
      <NavbarMenuItemLink to={routes.emergencyTasks}>
        <div className="flex h-10 w-10 items-center justify-center tablet:h-6 tablet:w-6">
          <EmergencyIcon className="max-h-full" />
        </div>
        <span>
          {isTablet
            ? 'ET' // t('Emergency tasks (short)')
            : t('Emergency tasks')}
        </span>
      </NavbarMenuItemLink>
      <NavbarMenuItemLink to={routes.dwgAndVendor}>
        <div className="flex h-10 w-10 items-center justify-center tablet:h-6 tablet:w-6">
          <VendorIcon className="max-h-full" />
        </div>
        <span className="w-14">{t('Vendor docs')}</span>
      </NavbarMenuItemLink>
      {isAdmin && (
        <>
          <NavbarMenuItemLink to={routes.users}>
            <div className="flex h-10 w-10 items-center justify-center tablet:h-6 tablet:w-6">
              <UserIcon className="max-h-full" />
            </div>
            <span className="w-14">{t('Users')}</span>
          </NavbarMenuItemLink>
          <NavbarMenuItemLink to={routes.companies}>
            <div className="flex h-10 w-10 items-center justify-center tablet:h-6 tablet:w-6">
              <CompanyIcon className="max-h-full" />
            </div>
            <span className="w-14">{t('Companies')}</span>
          </NavbarMenuItemLink>
          <NavbarMenuItemLink to={routes.logs}>
            <div className="flex h-10 w-10 items-center justify-center tablet:h-6 tablet:w-6">
              <LogsIcon className="max-h-full" />
            </div>
            <span className="w-14">{t('Logs')}</span>
          </NavbarMenuItemLink>
        </>
      )}
    </ul>
  );
};

export default NavbarMenu;
